import _ from 'lodash'
import qs from 'qs'
import { SWROptions, useSWRGet } from 'libs/swr'

export function useLatestChallengeForCollectionId(collectionId: string, options: SWROptions) {
  return useSWRGet(`challengeForCollection/${collectionId}`, options)
}

type ChallengeSearchParameters = {
  isChallengeActive?: boolean
  tag?: string
  type?: string
}

export function useUserChallenges(parameters: ChallengeSearchParameters, options: SWROptions) {
  const path = 'challenges/user'
  return useSWRGet(
    !_.isEmpty(parameters)
      ? `${path}?${qs.stringify({
          ...parameters,
        })}`
      : path,
    options
  )
}
