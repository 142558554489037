import _ from 'lodash'
import React from 'react'
import Announcement from 'components/Announcement'
import Container from 'components/Container'
import Link from 'components/Link'
import Loader from 'components/Loader'
import Row from 'components/Row'
import Rows from 'components/Rows'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import View from 'components/View'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as checkinApiRequest from 'libs/checkin-api-request'
import * as user from 'libs/user'
import * as userApiRequest from 'libs/user-api-request'
import styles from './Dashboard.module.scss'
import Activity from './components/Activity'
import Challenge from './components/Challenge'
import ExploreLessons from './components/ExploreLessons'
import LessonsFavorite from './components/LessonsFavorite'
import LiveLessons from './components/LiveLessons'
import Program from './components/Program'
import { ReactComponent as HouseSvg } from './images/house.svg'

export default function Dashboard() {
  const globalContext = useGlobalContext()
  const [data, setData] = React.useState({})
  const [prefersLiveLessons, setPrefersLiveLessons] = React.useState(false)

  React.useEffect(() => {
    if (!globalContext.user) return

    async function getData() {
      const response = await userApiRequest.getUserDashboard(globalContext.user.id)

      if (response.statusCode !== 200) return

      setData(response.data)
    }
    getData()
  }, [globalContext.user])

  React.useEffect(() => {
    async function getOnboardingResponses() {
      const onboardingResponse = await checkinApiRequest.getLatestUserCheckinResponses({
        checkinCode: 'default_onboarding_web',
      })

      if (onboardingResponse.statusCode !== 200 || !onboardingResponse?.data) return

      if (onboardingResponse.data?.motivation_to_start_bold?.responseValue === 'new exercises') {
        setPrefersLiveLessons(true)
      }
    }
    getOnboardingResponses()
  }, [])

  if (_.isEmpty(data)) {
    return (
      <View color="grey">
        <Loader overlay={false} />
      </View>
    )
  }

  const liveLessons = <LiveLessons data={data.liveLessons} />

  return (
    <div className="Dashboard" data-test-id="dashboard">
      <Announcement
        title="A new home for your saved classes"
        buttonProps={{
          children: 'Go to saved classes',
          to: paths.LESSONS_FAVORITE,
        }}
        section>
        Hi {globalContext.user.firstName}! We’ve moved your favorite classes to a new, dedicated
        page called{' '}
        <Link to={paths.LESSONS_FAVORITE}>
          <Text element="b">My saved classes</Text>
        </Link>
        . You can now find them{' '}
        {user.hasDashboard(globalContext.user) ? 'on your home page and' : ''} in the dropdown menu
        from the top navigation.
      </Announcement>
      <View color="grey">
        <Container flush size="xlargex">
          <Row size="xmedium">
            <TextWithIcon
              icon={<HouseSvg className={styles['title--icon']} />}
              element="h1"
              gutterSize="xsmall"
              size="xxxlarge"
              weight="semibold"
              className={styles.title}>
              Welcome {globalContext.user.firstName}!
              {globalContext.user.dateOfBirth &&
                user.isBirthdayToday(globalContext.user) &&
                ` and happy birthday!`}
            </TextWithIcon>
          </Row>
          <div className={styles.content}>
            <div className={styles.sidebar}>
              <Activity data={data.activity} />
              <Challenge />
            </div>
            <div className={styles.main}>
              <Rows size="xmedium">
                {prefersLiveLessons && liveLessons}
                <Program data={data.program} />
                <LessonsFavorite className={styles.lessons} />
                <ExploreLessons data={data.explore} className={styles.lessons} />
                {!prefersLiveLessons && liveLessons}
              </Rows>
            </div>
          </div>
        </Container>
      </View>
    </div>
  )
}
