import type { ExerciseProgram } from 'constants/types'

export const CLASS_TOO_EASY = 'easy'
export const CLASS_TOO_HARD = 'hard'
export const SITTING_DIFFICULTY_RESPONSE = '1'
export const STANDING_DIFFICULTY_RESPONSE = '2'

export function getProgramTitle(program: ExerciseProgram) {
  return program?.external_name || program?.externalName || program?.title
}
