// TODO: chevron
import classNames from 'classnames'
import { differenceInCalendarDays } from 'date-fns'
import pluralize from 'pluralize'
import React from 'react'
import Container from 'components/Container'
import Link from 'components/Link'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import * as events from 'constants/events'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as user from 'libs/user'
import styles from './SubscriptionTrialExpirationBanner.module.scss'
import { ReactComponent as ArrowRightSvg } from './images/arrow---right.svg'

type Props = Component

export default function SubscriptionTrialExpirationBanner({ className }: Props) {
  const globalContext = useGlobalContext()

  function handleClick() {
    globalContext.analytics?.trackEvent(events.SUBSCRIPTION_TRIAL_EXPIRATION_BANNER_CLICK)
  }

  if (
    !globalContext.isAuthed ||
    !user.isTrialUser(globalContext.user) ||
    !globalContext.user?.subscription?.trial_end
  )
    return null

  const days = differenceInCalendarDays(
    new Date(globalContext.user.subscription.trial_end * 1000),
    new Date()
  )

  const isOnboardingPaymentUser = user.isOnboardingPaymentRequired(globalContext.user)

  const bannerText = isOnboardingPaymentUser ? (
    <Text align="center" color="black" element="header" weight="semibold">
      Your 2-week trial ends in {pluralize('day', days, true)}.
    </Text>
  ) : (
    <Text align="center" casing="upper" color="black" element="header" weight="semibold">
      Your Bold Premium trial is ending in {pluralize('day', days, true)}.{' '}
      <TextWithIcon icon={<ArrowRightSvg />} iconPosition="right" inline className={styles.button}>
        Keep premium access
      </TextWithIcon>
    </Text>
  )

  if (days > 7 && !isOnboardingPaymentUser) return null

  return (
    <Link
      to={paths.SETTINGS_SUBSCRIPTION}
      className={classNames('SubscriptionTrialExpirationBanner', styles.this, className)}
      onClick={handleClick}>
      <Container full>{bannerText}</Container>
    </Link>
  )
}
