import { navigate } from 'gatsby'
import React from 'react'
import FormFieldsRequired from 'components/FormFieldsRequired'
import * as events from 'constants/events'
import paths from 'constants/paths'
import storage from 'constants/storage'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as cookie from 'libs/cookie'
import * as subscriptions from 'libs/subscriptions'
import * as user from 'libs/user'
import OnboardingLayout from 'onboarding/components/OnboardingLayout'
import OnboardingView from 'onboarding/components/OnboardingView'
import OnboardingProfileForm from './components/OnboardingProfileForm'

export default function OnboardingProfile() {
  useEventPageVisited(events.ONBOARDING_PROFILE_PAGE)
  const globalContext = useGlobalContext()
  const [disabled, setDisabled] = React.useState(false)

  async function submit(updatedUser: any) {
    if (!globalContext.user) return

    await globalContext.analytics?.trackEvent(
      events.PROFILE_CREATION_COMPLETE,
      { coupon: cookie.getCookie(storage.PROMO_CODE_KEY) },
      {
        trialLength: !user.isEnterpriseUser(updatedUser) ? subscriptions.TRIAL_DAYS : null,
        profileCompletedDate: new Date(),
      }
    )

    navigate(
      user.isOnboardingPaymentRequired(globalContext.user)
        ? paths.ONBOARDING_PAYMENT
        : paths.ONBOARDING_PROGRAM_BUILD,
      { replace: true }
    )
  }

  async function handleSuccess(updatedUser: any) {
    setDisabled(true)
    await submit(updatedUser)
  }

  return (
    <OnboardingLayout title="Profile">
      <OnboardingView
        small="Finish profile"
        title={`Complete sign up to get your personalized program ${
          user.isEnterpriseUser(globalContext.user) ? 'at no cost' : ''
        }`}>
        <FormFieldsRequired />
        <OnboardingProfileForm disabled={disabled} onSuccess={handleSuccess} />
      </OnboardingView>
    </OnboardingLayout>
  )
}
