import { useLocation } from '@reach/router'
import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { format, isThisWeek, isToday, isYesterday, parse } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import LessonBar from 'app/components/LessonBar'
import Button from 'components/Button'
import Divider from 'components/Divider'
import Loader from 'components/Loader'
import Row from 'components/Row'
import Text from 'components/Text'
import paths from 'constants/paths'
import { Lesson } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as date from 'libs/date'
import * as userApiRequest from 'libs/user-api-request'
import styles from './LessonsHistory.module.scss'

type Props = {
  onEmpty: () => void
}

export default function LessonsHistory({ onEmpty }: Props) {
  const globalContext = useGlobalContext()
  const location = useLocation()
  const [lessons, setLessons] = React.useState({})

  React.useEffect(() => {
    async function getData() {
      const response = await userApiRequest.getRecentLessons()

      const { data } = response

      if (_.isEmpty(data)) {
        onEmpty()
        return
      }

      // Convert to user's timezone
      const zonedLessons = data.map((item) => {
        const zonedDate = utcToZonedTime(new Date(item.lessonStartTs), date.TIMEZONE)
        const dayString = format(zonedDate, 'EEEE MMM d')
        return {
          ...item,
          zonedDate,
          dayString,
        }
      })

      // Group by day string
      const groupedByDay = _.groupBy(zonedLessons, 'dayString')

      // Dedup by lessonId within each day group
      const dedupedLessons: { [key: string]: any[] } = {}
      Object.keys(groupedByDay).forEach((day) => {
        dedupedLessons[day] = _.uniqBy(groupedByDay[day], 'id')
      })

      setLessons(dedupedLessons)
    }
    getData()
  }, [onEmpty])

  function handleLessonClick(lesson: Lesson) {
    if (!lesson) return
    globalContext.analytics?.trackEvent('Activity: Lesson history: Lesson: Clicked', {
      instructorName: lesson.instructorName,
      lessonId: lesson.id,
      videoTitle: lesson.lessonTitle || lesson.videoTitle,
    })

    navigate(`${paths.LESSON}${lesson.id}#explore`, {
      state: {
        from: `${location.pathname}#classes`,
        lessonTitle: lesson.lessonTitle || lesson.videoTitle,
      },
    })
  }

  return (
    <div className={classNames('LessonsHistory', styles.this)}>
      {_.isEmpty(lessons) ? (
        <Loader overlay={false} />
      ) : (
        <>
          {Object.keys(lessons).map((dayKey, index) => {
            const day = lessons[dayKey] // all unique lessons on that day

            // convert dateKey string back to Date, to compute if it's today or yesterday
            let dateFormatted = dayKey
            const dateParsed = parse(dayKey, 'EEEE MMM d', new Date())
            if (isToday(dateParsed)) {
              dateFormatted = 'Today'
            } else if (isYesterday(dateParsed)) {
              dateFormatted = 'Yesterday'
            } else if (isThisWeek(dateParsed)) {
              dateFormatted = format(dateParsed, 'EEEE')
            }

            return (
              <React.Fragment key={dayKey}>
                <Row size="xsmall">
                  <Text
                    element="h2"
                    id={date.getYYYY_MM_DDFromTimestamp(day[0].lessonStartTs)}
                    size="large"
                    weight="medium">
                    {dateFormatted}
                  </Text>
                </Row>
                {day.map((dayLesson, dayLessonIndex) => {
                  const isCompleted = dayLesson.lessonCompleted === 'true'
                  const isRestricted = dayLesson.restricted_access === 'admin_only'
                  const buttonCopy = isCompleted ? 'Rewatch class' : 'Continue'
                  let buttonContent = (
                    <Button color="white" full onClick={() => handleLessonClick(dayLesson)}>
                      {buttonCopy}
                    </Button>
                  )
                  if (dayLesson.lessonStreamId) {
                    buttonContent = (
                      <Tippy content="Live classes and reruns are not available to be rewatched">
                        <span>
                          <Text element="u" className={styles['lesson--explainer--button']}>
                            Rewatch not available
                          </Text>
                        </span>
                      </Tippy>
                    )
                  }
                  if (isRestricted) {
                    buttonContent = (
                      <Text weight="medium" className={styles['lesson--explainer']}>
                        Class no longer available.{' '}
                        <Tippy content="Classes can be removed for many reasons, mainly due to licensed music.">
                          <span>
                            <Text element="u" className={styles['lesson--explainer--button']}>
                              Why?
                            </Text>
                          </span>
                        </Tippy>
                      </Text>
                    )
                  }
                  return (
                    <Row
                      flush={dayLessonIndex === _.size(day) - 1}
                      key={dayLesson.id}
                      size="xmedium">
                      <LessonBar
                        button={buttonContent}
                        data={{ lesson: dayLesson }}
                        meta={dayLesson.classType?.join(', ')}
                        isCompleted={isCompleted}
                        className={classNames(
                          styles.lesson,
                          isRestricted && styles['lesson---restricted']
                        )}
                        onClick={
                          isRestricted || dayLesson.lessonStreamId ? undefined : handleLessonClick
                        }
                      />
                    </Row>
                  )
                })}
                {index !== _.size(lessons) - 1 && <Divider size="xmedium" thin />}
              </React.Fragment>
            )
          })}
        </>
      )}
    </div>
  )
}
