import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import LessonScheduleForm from 'app/components/LessonScheduleForm'
import Head from 'components/Head'
import Heading2 from 'components/Heading2'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import Text from 'components/Text'
import paths from 'constants/paths'
import statusCodes from 'constants/status-codes'
import storage from 'constants/storage'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as checkinApiRequest from 'libs/checkin-api-request'
import * as cookie from 'libs/cookie'
import * as date from 'libs/date'
import * as lessonLib from 'libs/lesson'
import * as notifications from 'libs/notifications'
import * as programApiRequest from 'libs/program-api-request'
import { useProgram } from 'libs/program-query'
import * as user from 'libs/user'
import * as userApiRequest from 'libs/user-api-request'

const REMIND_TIME = 180_000 //= 3 minutes

const ACCEPTABLE_PATHS = [paths.HOME_AUTHED, paths.LIVE_LESSONS, paths.EXPLORE]
function isOnAcceptablePage() {
  for (const path of ACCEPTABLE_PATHS) {
    if (window.location.pathname === path) return true
  }
  return false
}

type Props = Component

export default function FirstProgramLessonReminderModal({ className }: Props) {
  const globalContext = useGlobalContext()
  const { data: programData } = useProgram()
  const program = programData?.data
  const [reminderText, setReminderText] = React.useState('')
  const [confirmationText, setConfirmationText] = React.useState('')
  const durationRef = React.useRef('')
  const positionRef = React.useRef('')

  React.useEffect(() => {
    async function setUpModal() {
      if (
        !globalContext.user ||
        user.isAdminUser(globalContext.user) ||
        cookie.getCookie(storage.FIRST_LESSON_REMINDER_KEY) ||
        (await userApiRequest.hasRemindersScheduled()) ||
        user.isEnterpriseClinicalUser(globalContext.user) ||
        !isOnAcceptablePage() ||
        !globalContext.user?.isOnboardingCompleted
      )
        return

      if (globalContext.user?.allTimeClassesWatched !== 0) return

      let text = ''
      if (program == null) {
        const checkinResponse = await checkinApiRequest.getLatestUserCheckinResponses()
        if (checkinResponse.statusCode !== 200 || !checkinResponse?.data) return

        const durationResponse = checkinResponse.data?.preferred_class_duration?.responseValue
        if (durationResponse) {
          if (durationResponse.includes('-')) durationRef.current = durationResponse.split('-')[0]
          if (durationResponse.includes('+')) durationRef.current = durationResponse.split('+')[0]
        }
        const positionResponse = checkinResponse.data?.preferred_exercise_position?.responseValue
        if (positionResponse) {
          positionRef.current = positionResponse === 'seated' ? 'seated' : 'standing'
        }
        const motivationResponse = checkinResponse.data?.motivation_to_start_bold?.responseValue
        //if there is no motivation response, they are clinical and shouldn't have got here in the first place
        if (!motivationResponse) return
        let motivation = ''
        if (motivationResponse === 'joint pain') motivation = 'strength'
        if (motivationResponse === 'knee pain') motivation = 'strength'
        if (motivationResponse === 'new exercies') motivation = 'fitness'
        if (motivationResponse === 'balance') motivation = 'balance'

        text = `We picked out a great ${positionRef.current} ${motivation} class for you. Want a reminder?`
      } else {
        const firstBlock = program.exerciseProgram.exerciseBlocks[0]
        const lessonResponse = await programApiRequest.getUserLessonsForExerciseBlock(firstBlock.id)
        if (!_.isEmpty(lessonResponse.data)) {
          const lesson = lessonResponse.data[0]

          durationRef.current = lesson.duration ?? ''
          const classType = _.isEmpty(lesson.classType)
            ? ''
            : lesson.classType.join(' & ').toLowerCase()
          if (lesson.type) {
            positionRef.current = lessonLib.getType(lesson)

            // doesn't match type mapping elsewhere…could it?
            if (lesson.type === 'standing/floor') positionRef.current = 'standing'
          }
          text = `We picked out a great ${durationRef.current} minute ${positionRef.current} ${classType} class for you. Want a reminder?`
        }
      }
      if (!text) return

      globalContext.analytics?.trackEvent('First Lesson Reminder Modal shown', {
        duration: durationRef.current,
        position: positionRef.current,
      })

      setReminderText(text)
    }

    setTimeout(() => setUpModal(), REMIND_TIME)
  }, [program, globalContext.analytics, globalContext.user])

  function closeFormModal() {
    //never show this reminder to the user again
    cookie.createCookie(storage.FIRST_LESSON_REMINDER_KEY, 'true')
    setReminderText('')
  }

  function handleCloseConfirmationModal() {
    setConfirmationText('')
  }

  async function handleFormSubmit(values: any) {
    const timestamp = date.getDateFromTimestamp(values.date)
    timestamp.setHours(Number.parseInt(values.time, 10))

    const response = await userApiRequest.scheduleFirstLessonReminder({
      duration: durationRef.current,
      position: positionRef.current,
      scheduledAt: timestamp,
      phoneNumber: values.phoneNumber ?? undefined,
    })
    if (response.statusCode === statusCodes.POST_SUCCESS) {
      cookie.createCookie(storage.FIRST_LESSON_REMINDER_KEY, 'true')
      setConfirmationText(
        `Great! We'll send you a reminder this ${date.formatDisplayDayWithTime(timestamp)}`
      )
    } else {
      console.error(response.statusCode, response.message)
      notifications.notifyError()
    }
    closeFormModal()
  }

  const commonProps = {
    align: 'right-bottom',
    className: classNames('FirstProgramLessonReminderModal', className),
    isOpen: true,
    size: 'small',
  }

  if (confirmationText) {
    return (
      <>
        <Head bodyAttributes={{ class: 'body---hide-help-button' }} />
        <Modal {...commonProps} onRequestClose={handleCloseConfirmationModal}>
          <ModalBox align="left" size="small">
            <Heading2 level={2} levelStyle={5}>
              Reminder sent
            </Heading2>
            <Text element="p" flush>
              {confirmationText}
            </Text>
          </ModalBox>
        </Modal>
      </>
    )
  }

  if (reminderText) {
    return (
      <>
        <Head bodyAttributes={{ class: 'body---hide-help-button' }} />
        <Modal {...commonProps} onRequestClose={closeFormModal}>
          <ModalBox align="left" size="small">
            <Heading2 level={2} levelStyle={5}>
              Want to set a reminder?
            </Heading2>
            <Text element="p">{reminderText}</Text>
            <LessonScheduleForm onSubmit={handleFormSubmit} />
          </ModalBox>
        </Modal>
      </>
    )
  }

  return null
}
