import _ from 'lodash'
import qs from 'qs'
import * as api from 'libs/api'

type ChallengeSearchParameters = {
  isChallengeActive?: boolean
  tag?: string
  type?: string
}

export async function getActiveChallenges(parameters: ChallengeSearchParameters) {
  const path = 'challenges'

  try {
    return await api.get(!_.isEmpty(parameters) ? `${path}?${qs.stringify(parameters)}` : path)
  } catch (error) {
    return { error }
  }
}

export async function getUserChallenges(parameters: ChallengeSearchParameters) {
  const path = 'challenges/user'

  try {
    return await api.get(
      !_.isEmpty(parameters)
        ? `${path}?${qs.stringify({
            ...parameters,
          })}`
        : path
    )
  } catch (error) {
    return { error }
  }
}

export async function joinChallenge(challengeId: string) {
  try {
    return await api.post(`challenges/${challengeId}`)
  } catch (error) {
    return { error }
  }
}
