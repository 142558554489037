import { useLocation } from '@reach/router'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Media from 'react-media'
import Announcement from 'components/Announcement'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Head from 'components/Head'
import Header from 'components/Header'
import HeaderSubheader from 'components/HeaderSubheader'
import Heading2 from 'components/Heading2'
import Link, { type LinkProps } from 'components/Link'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as date from 'libs/date'
import * as user from 'libs/user'
import constants from 'styles/constants.module.scss'
import styles from './Activity.module.scss'
import Calendar from './components/Calendar'
import Challenges from './components/Challenges'
import LessonsHistory from './components/LessonsHistory'
import Stats from './components/Stats'

type ActivityLinkProps = {
  children: string
} & LinkProps

function ActivityLink({ children, ...props }: ActivityLinkProps) {
  return (
    <Link {...props} color="grey">
      <Text size="medium">{children}</Text>
    </Link>
  )
}

export default function Activity() {
  useEventPageVisited('Activity')
  const globalContext = useGlobalContext()
  const location = useLocation()
  const [hasLessonsHistory, setHasLessonsHistory] = React.useState(true)
  const [hasStats, setHasStats] = React.useState(true)
  const [linkActive, setLinkActive] = React.useState(location.hash)

  React.useEffect(() => {
    _.defer(() => {
      const { hash } = location

      if (!hash) return

      const element = document.querySelector(hash)

      if (!element) return

      element.scrollIntoView({ behavior: 'smooth' })
    })
  }, [location])

  const activityId = 'activity'
  const challengesId = 'challenges'
  const lessonHistoryId = 'classes'

  const navLeft = [
    {
      copy: 'Challenges',
      id: challengesId,
      hide: !user.hasChallenges(globalContext.user),
    },
    {
      copy: 'My activity',
      id: activityId,
    },
    {
      copy: 'Class history',
      id: lessonHistoryId,
      hide: !hasLessonsHistory,
    },
  ]

  const navRight = [
    {
      copy: 'Assessments',
      to: paths.ASSESSMENTS_AUTHED,
    },
    {
      copy: 'Badges',
      to: paths.BADGES,
    },
  ]

  return (
    <div className="Activity">
      <Head
        bodyAttributes={{
          class: 'body---grey',
        }}
      />
      <Header
        subheader={
          <HeaderSubheader>
            <nav className={styles.navs}>
              <Buttons>
                {navLeft
                  .filter((item) => !item.hide)
                  .map((item) => (
                    <ActivityLink
                      to={`#${item.id}`}
                      key={item.id}
                      className={classNames(
                        styles.link,
                        location.hash === item.id && styles['link---active'],
                        linkActive === item.id && styles['link---active']
                      )}
                      onClick={() => setLinkActive(item.id)}>
                      {item.copy}
                    </ActivityLink>
                  ))}
              </Buttons>
              <Buttons size="large" className={styles['nav---global']}>
                {navRight.map((item) => (
                  <ActivityLink to={item.to} key={item.copy} nav>
                    {item.copy}
                  </ActivityLink>
                ))}
              </Buttons>
            </nav>
          </HeaderSubheader>
        }
      />
      <View color="grey">
        <Container flush size="xxlarge">
          {hasStats && (
            <Row size="large">
              <Stats onEmpty={() => setHasStats(false)} />
            </Row>
          )}
          {user.hasChallenges(globalContext.user) && (
            <Row size="large">
              <Heading2 id={challengesId} levelStyle={4}>
                Challenges
              </Heading2>
              <Challenges />
            </Row>
          )}
          <Row size="large">
            <Heading2
              align="left"
              id={activityId}
              levelStyle={4}
              className={styles['calendar--title']}>
              My 30 day activity
              <Media query={{ maxWidth: constants.TABLET }}>
                <>
                  <div className={styles['calendar--title--divider']} />
                  <span className={styles['calendar--title--month']}>
                    {date.MONTHS[new Date().getMonth()]}
                  </span>
                </>
              </Media>
            </Heading2>
            <Calendar />
          </Row>
          {hasLessonsHistory && (
            <>
              <Heading2 id={lessonHistoryId} levelStyle={4}>
                Class history
              </Heading2>
              <Row>
                <Announcement
                  title="Add your most watched classes to your saved classes!"
                  buttonProps={{
                    children: 'Go to saved classes',
                    to: paths.LESSONS_FAVORITE,
                  }}>
                  You can find your saved classes{' '}
                  {user.hasDashboard(globalContext.user) ? 'on your home page and' : ''} in the
                  dropdown menu from the top navigation.
                </Announcement>
              </Row>
              <Row size="small">
                <LessonsHistory onEmpty={() => setHasLessonsHistory(false)} />
              </Row>
              <Text>End of class history</Text>
            </>
          )}
        </Container>
      </View>
    </div>
  )
}
