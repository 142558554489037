import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import Row from 'components/Row'
import Text from 'components/Text'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as assessmentsLib from 'libs/assessments'
import * as assessmentsApiRequest from 'libs/assessments-api-request'
import OnboardingBox from 'onboarding/components/OnboardingBox'
import OnboardingLayout from 'onboarding/components/OnboardingLayout'
import OnboardingView from 'onboarding/components/OnboardingView'

export default function OnboardingProgramIntroduction() {
  const globalContext = useGlobalContext()
  const [movementResults, setMovementResults] = React.useState<any | undefined>()

  useEventPageVisited('Onboarding assessments page')

  // TODO - handling back button??

  const handleClick = () => {
    // const fromApp = cookie.getCookie(storage.FROM_APP)
    // if (fromApp) {
    //   const accessToken = cookie.getCookie(storage.ACCESS_TOKEN_KEY)
    //   const queryString = url.turnObjectIntoQueryString({
    //     userId: globalContext.user.id,
    //     accessToken,
    //   })
    //   window.location.assign(`${urls.APP_URI}?${queryString}`)
    // }
    navigate(paths.ONBOARDING_PROFILE, { replace: true })
  }

  React.useEffect(() => {
    async function getAssessments() {
      const response = await assessmentsApiRequest.getAssessments(globalContext.user?.id)

      let movementAssessment = null
      if (response) {
        movementAssessment = response.filter((item) => item.title === 'Bold Lower Body Movement')
        setMovementResults(movementAssessment[0])
      }
    }
    getAssessments()
  }, [globalContext.user?.id])

  if (_.isEmpty(movementResults)) return null

  const sectionCopy = assessmentsLib.MOVEMENT_ASSESSMENT_RESULTS_MAP[movementResults.lastResult]
  const icon = _.get(sectionCopy, 'icon', 'person')
  const name = _.get(sectionCopy, 'name')
  const info = _.get(sectionCopy, 'info')

  if (!name || !info) return null

  return (
    <OnboardingLayout
      title="Movement"
      redirectIfOnboardingComplete={false}
      className="OnboardingAssessmentResults">
      <OnboardingView
        small="Your assessment results"
        title={
          globalContext?.user?.firstName ? (
            <>
              Here’s the type of exercises you’ll see in your program,{' '}
              <Text color="purple">{globalContext.user.firstName}</Text>!
            </>
          ) : (
            'Here’s the type of exercises you’ll see in your program'
          )
        }>
        <Row size="small">
          <OnboardingBox icon={icon}>
            <Text element="h2" weight="medium">
              {name}
            </Text>
            {info}
          </OnboardingBox>
        </Row>
        <Button level="primary" full onClick={handleClick}>
          Create my program
        </Button>
      </OnboardingView>
    </OnboardingLayout>
  )
}
