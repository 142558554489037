import React from 'react'
import DatePicker from 'react-date-picker'
import * as adminApiRequest from 'admin/libs/admin-api-request'
import ButtonWithModal from 'components/ButtonWithModal'
import Field from 'components/Field'
import Fields from 'components/Fields'
import Input from 'components/Input'
import Label from 'components/Label'
import statusCodes from 'constants/status-codes'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as notifications from 'libs/notifications'
import './AdminUsersPause.scss'

type Props = {
  userId: string
}

export default function AdminUsersPause({ userId }: Props) {
  const globalContext = useGlobalContext()
  const [startDate, setStartDate] = React.useState()
  const [endDate, setEndDate] = React.useState()
  const [isPaused, setIsPaused] = React.useState()
  const [reason, setReason] = React.useState()

  React.useEffect(() => {
    async function getUser() {
      const result = await adminApiRequest.getUser(userId)
      const user = result?.data
      const pauseData = user?.pauseData
      if (!user || !pauseData) return

      setIsPaused(user.paused || pauseData.pauseStartDate)
      setReason(pauseData.pauseReason)
      setStartDate(pauseData.pauseStartDate ? new Date(pauseData.pauseStartDate) : undefined)
      setEndDate(pauseData.pauseEndDate ? new Date(pauseData.pauseEndDate) : undefined)
    }
    getUser()
  }, [userId])

  async function pauseAccount() {
    let pacificTime
    if (startDate.getDate() !== new Date().getDate()) {
      pacificTime = new Date(startDate.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }))
      pacificTime.setDate(startDate.getDate())
      pacificTime.setHours(9)
    }

    const response = await adminApiRequest.pauseUser(
      userId,
      pacificTime ?? startDate,
      endDate,
      reason,
      false
    )
    if (response.statusCode !== statusCodes.SUCCESS) {
      notifications.notifyError('Something went wrong')
      return
    }
    globalContext.analytics?.trackEvent('Admin paused user', {
      admin: globalContext.user.email,
      affectedUserId: userId,
      startDate,
      endDate,
      reason,
    })
    notifications.notifySuccess('Successfully paused user account')
  }

  async function unpauseAccount() {
    const response = await adminApiRequest.pauseUser(userId, startDate, endDate, reason, true)
    if (response.statusCode !== statusCodes.SUCCESS) {
      notifications.notifyError('Something went wrong')
      return
    }
    globalContext.analytics?.trackEvent('Admin unpaused user', {
      admin: globalContext.user.email,
      affectedUserId: userId,
      reason,
    })
    notifications.notifySuccess('Successfully unpaused user account')
  }

  function handleConfirm() {
    if (isPaused) {
      unpauseAccount()
    } else {
      pauseAccount()
    }
  }

  const dateDisplayOptions = {
    month: 'long',
    day: 'numeric',
  }
  const confirmationTitle = isPaused
    ? 'Unpause account?'
    : startDate && endDate
      ? `Pause account from ${startDate.toLocaleDateString(
          'en-US',
          dateDisplayOptions
        )} until ${endDate.toLocaleDateString('en-US', dateDisplayOptions)} ?`
      : undefined

  const today = new Date()

  return (
    <div className="AdminUsersPause">
      <Fields>
        <Field>
          <Label>Start</Label>
          <DatePicker
            value={startDate}
            onChange={setStartDate}
            disabled={isPaused}
            minDate={today}
          />
        </Field>
        <Field>
          <Label>End</Label>
          <DatePicker value={endDate} onChange={setEndDate} disabled={isPaused} minDate={today} />
        </Field>
      </Fields>
      <Field>
        <Label>Reason</Label>
        <Input
          type="text"
          value={reason}
          onChange={(event) => setReason(event.target.value)}
          disabled={isPaused}
        />
      </Field>
      <ButtonWithModal
        modalProps={{
          cancelText: 'No',
          confirmButtonProps: {
            children: 'Yes, pause',
            color: 'red',
          },
          size: 'xsmall',
          theme: 'danger',
          title: confirmationTitle,
          onConfirm: handleConfirm,
        }}
        disabled={!startDate || !endDate}
        modalType="confirm">
        {isPaused ? 'Unpause account' : 'Pause account'}
      </ButtonWithModal>
    </div>
  )
}
