import classNames from 'classnames'
import React from 'react'
import BoldText from 'components/BoldText'
import Button from 'components/Button'
import Heading from 'components/Heading'
import Row from 'components/Row'
import Text from 'components/Text'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import * as subscriptions from 'libs/subscriptions'

type Props = Component

export default function SubscriptionUpgradeSection({ className }: Props) {
  const textProps = {
    casing: 'upper',
    color: 'purple',
    weight: 'semibold',
  }

  return (
    <div className={classNames('SubscriptionUpgradeSection', className)}>
      <Row size="xsmall">
        <Heading flush levelStyle={1}>
          Upgrade to
          <Text color="purple" wrap={false}>
            <BoldText />
            <Text {...textProps}>Premium</Text>
          </Text>{' '}
          by switching to a <Text {...textProps}>{subscriptions.PLANS.month.name2}</Text> or{' '}
          <Text {...textProps}>{subscriptions.PLANS.year.name2}</Text> membership plan today!
        </Heading>
      </Row>
      <Text element="p">
        With Bold Premium, you’ll get unlimited access to personalized programs, classes, and
        benefits provided by Bold’s expert trainers.
      </Text>
      <Button to={paths.SETTINGS_SUBSCRIPTION_CHANGE} size="xlarge">
        Change plan
      </Button>
    </div>
  )
}
