import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import { CircleProgress } from 'react-gradient-progress'
import TextLoop from 'react-text-loop'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import * as events from 'constants/events'
import paths from 'constants/paths'
import statusCodes from 'constants/status-codes'
import storage from 'constants/storage'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as cookie from 'libs/cookie'
import * as notifications from 'libs/notifications'
import { useProgram } from 'libs/program-query'
import * as programs from 'libs/programs'
import * as url from 'libs/url'
import * as userApiRequest from 'libs/user-api-request'
import constants from 'styles/constants.module.scss'
import styles from './ProgramBuild.module.scss'

type Props = {
  onComplete?: () => void
} & Component

export default function ProgramBuild({ className, onComplete }: Props) {
  const globalContext = useGlobalContext()
  const { data: programData, mutate: mutateProgram } = useProgram(cookie.getCookie(storage.UID_KEY))
  const [conditionCopy, setConditionCopy] = React.useState('Configuring movements')
  const [hasProgramError, setHasProgramError] = React.useState(false)
  const [percentage, setPercentage] = React.useState(0)

  const { lessonFeedback } = url.getQueryString()
  const requestedChange = !_.isEmpty(lessonFeedback)
  const copyArray = requestedChange
    ? [
        'Adjusting your program',
        `Making your classes ${
          lessonFeedback === programs.CLASS_TOO_EASY ? 'more' : 'less'
        } challenging`,
        'Finalizing your recommended classes',
      ]
    : ['Configuring preferences', 'Configuring background', conditionCopy]

  const complete = React.useCallback(() => {
    if (onComplete) {
      onComplete()
    } else {
      navigate(
        requestedChange
          ? `${paths.PROGRAM}?feedback=${
              lessonFeedback === programs.CLASS_TOO_EASY ? 'more' : 'less'
            }`
          : paths.PROGRAM,
        { replace: true }
      )
    }
  }, [lessonFeedback, requestedChange, onComplete])

  React.useEffect(() => {
    async function getPersonalizationInformation() {
      const generatedResponse = await userApiRequest.getLatestUserProfile()
      if (generatedResponse.statusCode !== 200 || !generatedResponse?.data) return

      const generatedConditions = generatedResponse?.data?.latestProfile?.conditions

      if (!_.isEmpty(generatedConditions)) {
        setConditionCopy(`Adjusting for ${generatedConditions[0]}`)
      }
    }
    getPersonalizationInformation()
  })

  React.useEffect(() => {
    async function updateProgram() {
      const { changeRequested } = url.getQueryString()

      if (changeRequested === 'true' || !programData?.data) {
        const response = await userApiRequest.setUserExerciseProgram(
          programData?.data?.exerciseProgramId
        )
        if (response.statusCode === statusCodes.NO_OP) {
          notifications.notifyError(
            'Oops, your answers matched you to your current program. Please select different answers if you’d like to change your program.'
          )
          complete()
        }

        globalContext.updateUser()
        mutateProgram()
      } else if (!_.isEmpty(lessonFeedback)) {
        const response = await userApiRequest.switchUserExerciseProgram(lessonFeedback)

        let success = 'true'
        if (response?.statusCode !== 200) {
          // for now, keep their program the same but tell them the adjustment worked & log event
          success = 'false'
        } else {
          globalContext.updateUser()
          mutateProgram()
        }
        await globalContext?.analytics.trackEvent(events.SWITCH_USER_EXERCISE_PROGRAM, {
          newProgram: response?.data?.title,
          previousProgram: globalContext?.user?.currentExerciseProgram,
          reason: `previous_program_too_${lessonFeedback}`,
          success,
          switchInitiatedFrom: 'post_first_lesson_feedback',
          error: response?.message || response?.error?.message,
        })
      }
    }
    updateProgram()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete])

  React.useEffect(() => {
    setTimeout(() => {
      setPercentage(10)

      setTimeout(() => {
        setPercentage(30)

        setTimeout(() => {
          setPercentage(60)

          setTimeout(() => {
            setPercentage(90)

            setTimeout(() => {
              setPercentage(100)
            }, 600)
          }, 800)
        }, 1200)
      }, 800)
    }, 400)
  }, [])

  React.useEffect(() => {
    if (percentage !== 100) return

    setTimeout(() => {
      if (programData.statusCode === statusCodes.SUCCESS) {
        complete()
      } else {
        setHasProgramError(true)
      }
    }, 1000)
  }, [
    complete,
    lessonFeedback,
    percentage,
    programData,
    globalContext.analytics,
    globalContext.user?.currentExerciseProgram,
  ])

  return (
    <View className={classNames('ProgramBuild', styles.this, className)}>
      {hasProgramError ? (
        <Text element="p" flush>
          Sorry, something went wrong attempting to build your program. Please try again later.
        </Text>
      ) : (
        <div>
          <Row size="smallx">
            <div className={styles.progress}>
              <div className={styles['progress--circle']}>
                <CircleProgress
                  percentage={percentage}
                  primaryColor={[constants.PURPLE, constants.PURPLE]}
                  secondaryColor="#f5f5f5"
                  strokeWidth={14}
                  width={138}
                />
              </div>
            </div>
          </Row>
          <TextLoop interval={1600} noWrap className={styles.words}>
            {copyArray.map((item) => (
              <Text align="center" key={item} className={styles['words--item']}>
                {item}
              </Text>
            ))}
          </TextLoop>
        </div>
      )}
    </View>
  )
}
