import { differenceInCalendarDays } from 'date-fns'
import pluralize from 'pluralize'
import React from 'react'
import SubscriptionPlanWithPrice from 'app/components/settings/SubscriptionPlanWithPrice'
import SubscriptionCancelButton from 'app/components/SubscriptionCancelButton'
import SubscriptionChangeButton from 'app/components/SubscriptionChangeButton'
import SubscriptionPaymentMethodChangeButton from 'app/components/SubscriptionPaymentMethodChangeButton'
import SubscriptionReactivateButton from 'app/components/SubscriptionReactivateButton'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import DateTime from 'components/DateTime'
import Fieldset from 'components/Fieldset'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import EnterpriseEligibleButton from 'enterprise/components/EnterpriseEligibleButton'
import * as subscriptions from 'libs/subscriptions'
import * as user from 'libs/user'

export default function Subscription(props) {
  const globalContext = useGlobalContext()

  function handleEnterpriseEligibleButtonClick() {
    globalContext.analytics?.trackEvent(events.SETTINGS_ENTERPRISE_ELIGIBILITY_BUTTON_CLICK)
  }

  const buttonProps = {
    level: 'text',
    size: 'small',
  }

  const textProps = {
    element: 'p',
    flush: true,
    size: 'small',
  }

  if (globalContext.isEnterprise && globalContext.user.enterpriseMember?.validated) return null

  const hasCancelledSubscription = globalContext.user?.subscription?.cancel_at_period_end

  return (
    <Fieldset
      {...props}
      actions={
        <Buttons>
          <SubscriptionPaymentMethodChangeButton {...buttonProps} />
          {!!globalContext.user?.subscription &&
            !subscriptions.isFreePlan(globalContext.user?.subscription?.plan) && (
              <Button to={paths.SETTINGS_INVOICES} {...buttonProps}>
                View invoices
              </Button>
            )}
        </Buttons>
      }
      footer={
        <>
          <Row size="xsmall">
            <Text element="div" size="small">
              <Buttons align="right">
                <SubscriptionChangeButton {...buttonProps} color="purple" />
                <SubscriptionCancelButton {...buttonProps} />
                <SubscriptionReactivateButton {...buttonProps} />
              </Buttons>
            </Text>
          </Row>
          <EnterpriseEligibleButton
            {...buttonProps}
            icon="arrow-right"
            color="inherit"
            onClick={handleEnterpriseEligibleButtonClick}>
            See if your health plan offers Bold free
          </EnterpriseEligibleButton>
        </>
      }
      className="Subscription">
      <Row flush={!user.isTrialUser(globalContext.user) && !hasCancelledSubscription} size="small">
        <SubscriptionPlanWithPrice />
      </Row>
      {user.isTrialUser(globalContext.user) &&
        !hasCancelledSubscription &&
        !!globalContext.user?.subscription?.trial_end && (
          <Text {...textProps}>
            Your free Bold Premium trial is ending in{' '}
            {pluralize(
              'day',
              differenceInCalendarDays(
                new Date(globalContext.user.subscription.trial_end * 1000),
                new Date()
              ),
              true
            )}
            .{' '}
            {!globalContext.user.defaultStripePaymentSource && (
              <SubscriptionChangeButton color="purple" level="text">
                Want to keep access?
              </SubscriptionChangeButton>
            )}
          </Text>
        )}
      {hasCancelledSubscription && (
        <Text {...textProps}>
          {globalContext.user?.subscription?.trial_end ? (
            <>
              Your subscription has been cancelled, and you will not be charged again. You will
              retain access to Bold Premium until your trial ends on{' '}
              <DateTime data={new Date(globalContext.user.subscription.trial_end * 1000)} />.
            </>
          ) : (
            'Your subscription has been cancelled, and you will not be charged again.'
          )}{' '}
          <SubscriptionReactivateButton color="purple" level="text">
            Want to reactivate your subscription?
          </SubscriptionReactivateButton>
        </Text>
      )}
    </Fieldset>
  )
}
