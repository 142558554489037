import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import * as adminApiRequest from 'admin/libs/admin-api-request'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import FieldEmail from 'components/FieldEmail'
import FieldFirstName from 'components/FieldFirstName'
import FieldLastName from 'components/FieldLastName'
import FieldPhoneNumber from 'components/FieldPhoneNumber'
import FormValidationNotification from 'components/FormValidationNotification'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import Row from 'components/Row'
import Text from 'components/Text'
import statusCodes from 'constants/status-codes'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as notifications from 'libs/notifications'
import * as validations from 'libs/validations'

function ConfirmBox({ oldValue, newValue, setConfirmed }) {
  return (
    <Modal isOpen shouldCloseOnOverlayClick={false}>
      <ModalBox>
        <Row>
          <Text>Confirm Update</Text>
        </Row>
        <Row>
          <Text>Old Value</Text>
        </Row>
        <Row>{oldValue}</Row>
        <Row>
          <Text>New Value</Text>
        </Row>
        <Row>{newValue}</Row>
        <Button onClick={() => setConfirmed(true)}>Confirm</Button>
        <Button onClick={() => setConfirmed(false)}>Cancel</Button>
      </ModalBox>
    </Modal>
  )
}

type Props = {
  fieldInfo: {
    currentValue: string
    fieldType: string
  }
  onComplete: () => void
  userId: string
}
export default function EditBox({ fieldInfo, onComplete, userId }: Props) {
  const globalContext = useGlobalContext()
  const [showConfirm, setShowConfirm] = React.useState(false)
  const [formikValues, setFormikValues] = React.useState(null)

  if (!fieldInfo) return null

  const { currentValue, fieldType } = fieldInfo

  let validationSchema
  let field
  switch (fieldType) {
    case 'firstName': {
      validationSchema = { firstName: validations.NAME }
      field = <FieldFirstName />
      break
    }
    case 'lastName': {
      validationSchema = { lastName: validations.NAME }
      field = <FieldLastName />
      break
    }
    case 'email': {
      validationSchema = { email: validations.EMAIL }
      field = <FieldEmail />
      break
    }
    case 'phoneNumber': {
      validationSchema = { phoneNumber: validations.PHONE_NUMBER }
      field = <FieldPhoneNumber />
      break
    }
    default: {
      break
    }
  }

  async function submit(values, formikActions) {
    const response = await adminApiRequest.updateUser(userId, values)
    formikActions.setSubmitting(false)

    if (response.statusCode !== statusCodes.POST_SUCCESS) {
      console.error(response)
      notifications.notifyError('Something went wrong')
      return
    }

    notifications.notifySuccess('Field updated!')

    globalContext.analytics?.trackEvent('Admin changed user profile', {
      admin: globalContext.user.email,
      affectedUserId: userId,
      fieldType,
      previousValue: currentValue,
      newValue: Object.values(values)[0],
    })

    onComplete()
  }

  function handleSubmit(values, formikActions) {
    setFormikValues({
      values,
      formikActions,
    })
    setShowConfirm(true)
  }

  async function handleConfirm(isConfirmed) {
    if (isConfirmed) {
      const { values, formikActions } = formikValues
      await submit(values, formikActions)
    } else {
      setFormikValues(null)
    }
    setShowConfirm(false)
  }

  return (
    <Modal isOpen onRequestClose={onComplete} shouldCloseOnOverlayClick={false}>
      <ModalBox>
        <Formik
          initialValues={{}}
          validationSchema={Yup.object(validationSchema)}
          onSubmit={handleSubmit}>
          {(formikProps) => (
            <Form>
              <FormValidationNotification />
              <Row>
                <Text>Current value</Text>
              </Row>
              <Row>{currentValue}</Row>
              <Row>
                <Text>New Value</Text>
                {field}
              </Row>
              <Row size="small">
                <Container flush size="xsmall">
                  <Buttons>
                    <Button disabled={formikProps.isSubmitting} onClick={formikProps.submitForm}>
                      Continue
                    </Button>
                    <Button disabled={formikProps.isSubmitting} onClick={onComplete}>
                      Cancel
                    </Button>
                  </Buttons>
                </Container>
              </Row>
            </Form>
          )}
        </Formik>
        {showConfirm && formikValues && (
          <ConfirmBox
            oldValue={currentValue}
            newValue={Object.values(formikValues.values)[0]}
            setConfirmed={handleConfirm}
          />
        )}
      </ModalBox>
    </Modal>
  )
}
