import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import ActivityView from 'app/components/ActivityView'
import Button from 'components/Button'
import Container from 'components/Container'
import Image, { type ImageProps } from 'components/Image'
import Row from 'components/Row'
import Title from 'components/Title'
import paths from 'constants/paths'
import users from 'constants/users'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import assessmentBalanceBadgeImage from 'images/badges/assessment---balance.png'
import assessmentMobilityBadgeImage from 'images/badges/assessment---mobility.png'
import assessmentStrengthBadgeImage from 'images/badges/assessment---strength.png'
import challenge202108BadgeImage from 'images/badges/challenge---2021-08.png'
import challenge202109BadgeImage from 'images/badges/challenge---2021-09.png'
import challenge202110BadgeImage from 'images/badges/challenge---2021-10.png'
import challenge2022MarchMadnessBadgeImage from 'images/badges/challenge---2022-march-madness.png'
import challengeFestiveFlowBadgeImage from 'images/badges/challenge-festive-flow.png'
import challengeHeartHealthyBadgeImage from 'images/badges/challenge-healthy-heart-2023.png'
import januaryBalanceChallenge from 'images/badges/challenge-january-balance.png'
import challengeStartStrong2023BadgeImage from 'images/badges/challenge-start-strong-2023.png'
import lessons1BadgeImage from 'images/badges/lessons---1.png'
import lessons3BadgeImage from 'images/badges/lessons---3.png'
import minutes60BadgeImage from 'images/badges/minutes---60.png'
import minutes120BadgeImage from 'images/badges/minutes---120.png'
import minutes180BadgeImage from 'images/badges/minutes---180.png'
import minutes240BadgeImage from 'images/badges/minutes---240.png'
import minutes300BadgeImage from 'images/badges/minutes---300.png'
import minutes360BadgeImage from 'images/badges/minutes---360.png'
import minutes420BadgeImage from 'images/badges/minutes---420.png'
import moverAndShakerBadgeImage from 'images/badges/mover-and-shaker.png'
import programCompleteBadgeImage from 'images/badges/program-complete-badge.svg'
import september2022BalanceChallengeBadgeImage from 'images/badges/sept-balance-badge.png'
import welcomeBadgeImage from 'images/badges/welcome.png'
import workouts5BadgeImage from 'images/badges/workouts---5.png'
import workouts10BadgeImage from 'images/badges/workouts---10.png'
import workouts20BadgeImage from 'images/badges/workouts---20.png'
import workouts30BadgeImage from 'images/badges/workouts---30.png'
import workouts40BadgeImage from 'images/badges/workouts---40.png'
import workouts50BadgeImage from 'images/badges/workouts---50.png'
import workouts75BadgeImage from 'images/badges/workouts---75.png'
import workouts100BadgeImage from 'images/badges/workouts---100.png'
import workouts125BadgeImage from 'images/badges/workouts---125.png'
import workouts150BadgeImage from 'images/badges/workouts---150.png'
import workouts175BadgeImage from 'images/badges/workouts---175.png'
import workouts250BadgeImage from 'images/badges/workouts---250.png'
import workouts500BadgeImage from 'images/badges/workouts---500.png'
import * as assessmentsLib from 'libs/assessments'
import * as assessmentsApiRequest from 'libs/assessments-api-request'
import * as challengeApiRequest from 'libs/challenge-api-request'
import * as time from 'libs/time'
import * as user from 'libs/user'
import * as userApiRequest from 'libs/user-api-request'
import styles from './Badges.module.scss'

type BadgeProps = {
  active?: boolean
} & ImageProps

function Badge({ active, ...props }: BadgeProps) {
  return (
    <div className={styles['badges--item']}>
      <Image {...props} className={classNames(styles.badge, active && styles['badge---active'])} />
    </div>
  )
}

export default function Badges() {
  useEventPageVisited('Badges')
  const globalContext = useGlobalContext()
  const [assessmentBalance, setAssessmentBalance] = React.useState(false)
  const [assessmentMobility, setAssessmentMobility] = React.useState(false)
  const [assessmentStrength, setAssessmentStrength] = React.useState(false)
  const [previousUserChallenges, setPreviousUserChallenges] = React.useState([])
  const [userProgramsProgress, setUserProgramsProgress] = React.useState()
  const [watchedTime, setWatchedTime] = React.useState(0)
  const getAssessments = React.useCallback(async () => {
    const assessments = await assessmentsApiRequest.getAssessments(globalContext.user?.id)

    function isAssessmentCompleted(internalId) {
      return !_.isEmpty(assessments.find((item) => item.internalId === internalId)?.lastRecordDate)
    }

    if (assessments) {
      setAssessmentBalance(isAssessmentCompleted(assessmentsLib.ASSESSMENT_BALANCE_INTERNAL_ID))
      setAssessmentMobility(isAssessmentCompleted(assessmentsLib.ASSESSMENT_MOBILITY_INTERNAL_ID))
      setAssessmentStrength(
        isAssessmentCompleted(assessmentsLib.ASSESSMENT_STRENGTH_LOWER_BODY_INTERNAL_ID)
      )
    }
  }, [globalContext.user?.id])

  React.useEffect(() => {
    getAssessments()
  }, [getAssessments])

  async function getPreviousUserChallenges() {
    const previousUserChallengesResponse = await challengeApiRequest.getUserChallenges({
      type: 'oneoff',
      complete: true,
    })
    setPreviousUserChallenges(previousUserChallengesResponse.data)
  }

  React.useEffect(() => {
    getPreviousUserChallenges()
  }, [])

  async function getUserProgramsProgress() {
    const userProgramsProgressResponse = await userApiRequest.getUserExerciseProgramsProgress()
    setUserProgramsProgress(userProgramsProgressResponse.data)
  }

  React.useEffect(() => {
    getUserProgramsProgress()
  }, [])

  const getWatchedTime = React.useCallback(async () => {
    const userStatistics = await userApiRequest.getUserStatistics(globalContext.user?.id)

    setWatchedTime(time.secondsToMinutes(userStatistics.data?.stats?.duration))
  }, [globalContext.user?.id])

  React.useEffect(() => {
    getWatchedTime()
  }, [getWatchedTime])

  return (
    <ActivityView title="Badges" className="Badges">
      <Container flush>
        <Title
          subtext="You’re doing great! Keep on taking classes and assessing your progress to continue unlocking badges. We’re impressed."
          cta={
            <Button
              to={user.isFreeUser(globalContext.user) ? paths.EXPLORE : paths.PROGRAM}
              size="xlarge">
              Take a class
            </Button>
          }
          align="center"
          size="small"
        />
        <Row>
          <div className={styles.badges}>
            <Badge src={welcomeBadgeImage} active alt="Welcome badge" />
            {userProgramsProgress?.map(
              (userExerciseProgram) =>
                userExerciseProgram.percentComplete === 100 && (
                  <Badge src={programCompleteBadgeImage} active alt="Program Complete Badge" />
                )
            )}
            <Badge
              src={assessmentBalanceBadgeImage}
              active={assessmentBalance}
              alt="Balance Assessment badge"
            />
            <Badge
              src={assessmentMobilityBadgeImage}
              active={assessmentMobility}
              alt="Mobility Assessment badge"
            />
            <Badge
              src={assessmentStrengthBadgeImage}
              active={assessmentStrength}
              alt="Strength Assessment badge"
            />
            <Badge
              src={lessons1BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 1}
              alt="1st Class badge"
            />
            <Badge
              src={lessons3BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 3}
              alt="3rd Class badge"
            />
            <Badge
              src={workouts5BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 5}
              alt="5 Workouts badge"
            />
            <Badge
              src={workouts10BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 10}
              alt="10 Workouts badge"
            />
            <Badge
              src={workouts20BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 20}
              alt="20 Workouts badge"
            />
            <Badge
              src={workouts30BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 30}
              alt="30 Workouts badge"
            />
            <Badge
              src={workouts40BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 40}
              alt="40 Workouts badge"
            />
            <Badge
              src={workouts50BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 50}
              alt="50 Workouts badge"
            />
            <Badge
              src={workouts75BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 75}
              alt="75 Workouts badge"
            />
            <Badge
              src={workouts100BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 100}
              alt="100 Workouts badge"
            />
            <Badge
              src={workouts125BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 125}
              alt="125 Workouts badge"
            />
            <Badge
              src={workouts150BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 150}
              alt="150 Workouts badge"
            />
            <Badge
              src={workouts175BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 175}
              alt="175 Workouts badge"
            />
            <Badge
              src={workouts250BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 250}
              alt="250 Workouts badge"
            />
            <Badge
              src={workouts500BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 500}
              alt="500 Workouts badge"
            />
            <Badge
              src={minutes60BadgeImage}
              active={watchedTime >= 60}
              alt="60 Minutes Milestone badge"
            />
            <Badge
              src={minutes120BadgeImage}
              active={watchedTime >= 120}
              alt="120 Minutes Milestone badge"
            />
            <Badge
              src={minutes180BadgeImage}
              active={watchedTime >= 180}
              alt="180 Minutes Milestone badge"
            />
            <Badge
              src={minutes240BadgeImage}
              active={watchedTime >= 240}
              alt="240 Minutes Milestone badge"
            />
            <Badge
              src={minutes300BadgeImage}
              active={watchedTime >= 300}
              alt="300 Minutes Milestone badge"
            />
            <Badge
              src={minutes360BadgeImage}
              active={watchedTime >= 360}
              alt="360 Minutes Milestone badge"
            />
            <Badge
              src={minutes420BadgeImage}
              active={watchedTime >= 420}
              alt="420 Minutes Milestone badge"
            />
            {user.hasChallenges(globalContext.user) && (
              <>
                <Badge
                  src={januaryBalanceChallenge}
                  active={previousUserChallenges.find(
                    (userChallenge) =>
                      userChallenge.challenge.name === 'January Balance Challenge' &&
                      userChallenge.progress >= userChallenge.challenge.threshold
                  )}
                  alt="January Balance Challenge badge"
                />
                <Badge
                  src={challengeHeartHealthyBadgeImage}
                  active={previousUserChallenges.find(
                    (userChallenge) =>
                      userChallenge.challenge.name === 'Healthy Heart Challenge' &&
                      userChallenge.progress >= userChallenge.challenge.threshold
                  )}
                  alt="Healthy Heart Challenge badge"
                />
                <Badge
                  src={challengeFestiveFlowBadgeImage}
                  active={previousUserChallenges.find(
                    (userChallenge) =>
                      userChallenge.challenge.name === 'Festive Flow Challenge' &&
                      userChallenge.progress >= userChallenge.challenge.threshold
                  )}
                  alt="Festive Flow Challenge badge"
                />
                <Badge
                  src={challengeStartStrong2023BadgeImage}
                  active={previousUserChallenges.find(
                    (userChallenge) =>
                      userChallenge.challenge.name === 'Start Strong Challenge' &&
                      userChallenge.progress >= userChallenge.challenge.threshold
                  )}
                  alt="Start Strong Challenge badge"
                />
                <Badge
                  src={challenge202108BadgeImage}
                  active={users.CHALLENGE_2021_08_EMAILS.includes(
                    globalContext.user?.email?.toLowerCase()
                  )}
                  alt="August 2021 Challenge badge"
                />
                <Badge
                  src={challenge202109BadgeImage}
                  active={previousUserChallenges.find(
                    (userChallenge) =>
                      userChallenge.challenge.name === 'September Balance Challenge' &&
                      userChallenge.progress >= userChallenge.challenge.threshold
                  )}
                  alt="September 2021 Challenge badge"
                />
                <Badge
                  src={september2022BalanceChallengeBadgeImage}
                  active={previousUserChallenges.find(
                    (userChallenge) =>
                      userChallenge.challenge.name === 'September 2022 Balance Challenge' &&
                      userChallenge.progress >= userChallenge.challenge.threshold
                  )}
                  alt="September 2022 Balance Challenge badge"
                />
                <Badge
                  src={challenge202110BadgeImage}
                  active={users.CHALLENGE_2021_10_EMAILS.includes(
                    globalContext.user?.email?.toLowerCase()
                  )}
                  alt="October 2021 Challenge badge"
                />
                <Badge
                  src={challenge2022MarchMadnessBadgeImage}
                  active={previousUserChallenges.find(
                    (userChallenge) =>
                      userChallenge.challenge.name === 'March Madness Challenge' &&
                      userChallenge.progress >= userChallenge.challenge.threshold
                  )}
                  alt="March Madness Challenge badge"
                />
                <Badge
                  src={moverAndShakerBadgeImage}
                  active={previousUserChallenges.find(
                    (userChallenge) =>
                      userChallenge.tag === 'skill_building' && userChallenge.complete === true
                  )}
                  alt="Mover and shaker badge"
                />
              </>
            )}
          </div>
        </Row>
      </Container>
    </ActivityView>
  )
}
